import classNames from 'classnames';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { RiSaveFill } from 'react-icons/ri';
import Button from '../../components/Button';
import Card from '../../components/Card';
import HelpIcon from '../../components/HelpIcon';
import { showAlert } from '../../components/alert/AlertSystem';
import {
  createContextURL,
  listContextURL,
  updateContextURL,
} from '../../configs/api';
import UserContext, { UserContextType } from '../../contexts/UserContext';
import { ContextItem } from '../../types/context';
import { Nullable, Undefineable } from '../../types/nullable';
import { jsonGet, jsonPost } from '../../utils/http';
import styles from './page.module.scss';

export async function listContexts(
  userCtx: UserContextType | undefined
): Promise<Nullable<Array<ContextItem>>> {
  let resultObj: any = await jsonGet(listContextURL, userCtx);
  if (resultObj === null) {
    return null;
  }
  let contexts = resultObj as Array<ContextItem>;
  return sortContexts(contexts);
}

async function updateContext(
  userCtx: UserContextType,
  context: ContextItem
): Promise<void> {
  await jsonPost(updateContextURL, context, userCtx);
}

async function createContext(
  userCtx: UserContextType,
  context: ContextItem
): Promise<void> {
  await jsonPost(createContextURL, context, userCtx);
}

// sortContexts makes the contexts sorted by name, but set the default context to the first one.
function sortContexts(contexts: Array<ContextItem>): Array<ContextItem> {
  contexts.sort((a, b) => a.name.localeCompare(b.name));
  for (let i = 0; i < contexts.length; i++) {
    if (contexts[i].name === 'default') {
      if (i === 0) {
        break;
      }
      let temp = contexts[0];
      contexts[0] = contexts[i];
      contexts[i] = temp;
      break;
    }
  }
  return contexts;
}

const Page = () => {
  const [contexts, setContexts] = useState<Array<ContextItem>>([]);
  const [selContext, setSelContext] = useState<Undefineable<ContextItem>>();
  const userCtx = useContext(UserContext);

  const dialogRef = useRef<HTMLDialogElement>(null);
  const pathRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const fetchContexts = () => {
    listContexts(userCtx).then((contexts) => {
      if (contexts === null) {
        return;
      }
      setContexts(contexts);
      if (selContext) {
        for (let ctx of contexts) {
          if (ctx.id === selContext.id) {
            setSelContext(ctx);
            return;
          }
        }
        setSelContext(undefined);
      }
    });
  };
  useEffect(() => {
    fetchContexts();
  }, []);
  const addContext = (context: ContextItem) => {
    createContext(userCtx, context).then(() => {
      fetchContexts();
    });
  };

  if (!userCtx.user) {
    return <div>Please login as admin to access this page.</div>;
  }

  return (
    <div className={styles.container}>
      <dialog className={styles.dialog} ref={dialogRef}>
        <div className={styles.form_box}>
          <div className={styles.form_item}>
            <label>Path</label>
            <input type="text" placeholder="Path" ref={pathRef} />
          </div>
          <div className={styles.form_item}>
            <label>Name</label>
            <input type="text" placeholder="Name" ref={nameRef} />
          </div>
          <div className={styles.form_item}>
            <label>Content</label>
            <textarea placeholder="Content" ref={contentRef}></textarea>
          </div>
          <div>
            <Button
              type="contained"
              onClick={() => {
                if (
                  pathRef.current?.value === '' ||
                  nameRef.current?.value === '' ||
                  contentRef.current?.value === ''
                ) {
                  showAlert('Please fill all fields');
                  return;
                }
                addContext({
                  id: '',
                  path: pathRef.current!.value,
                  name: nameRef.current!.value,
                  content: contentRef.current!.value,
                });
                dialogRef.current?.close();
              }}
            >
              OK
            </Button>
            <span style={{ width: '1.5rem', display: 'inline-block' }}></span>
            <Button
              type="text"
              onClick={() => {
                dialogRef.current?.close();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </dialog>
      <div className={styles['name-container']}>
        <Card
          width="100%"
          height="100%"
          head={
            <p>
              Name {<HelpIcon info="You can add different contexts." />}
              <span
                className={styles.add}
                onClick={() => {
                  dialogRef.current?.showModal();
                }}
              >
                ADD
              </span>
            </p>
          }
        >
          <ul>
            {contexts.map((ctx) => (
              <li
                key={ctx.name}
                className={classNames({
                  [styles.li]: true,
                  [styles.selected]: selContext && selContext.name === ctx.name,
                })}
                onClick={() => {
                  setSelContext(ctx);
                }}
              >
                {ctx.name}
              </li>
            ))}
          </ul>
        </Card>
      </div>
      <ContextContent
        key={
          selContext ? selContext.id + selContext.name + selContext.content : 0
        }
        item={selContext}
        onUpdated={fetchContexts}
      />
    </div>
  );
};

type ContextContentProps = {
  item?: ContextItem;
  onUpdated?: () => void;
};

const ContextContent: FC<ContextContentProps> = (props) => {
  const [content, setContent] = useState<string>(props.item?.content ?? '');
  const userCtx = useContext(UserContext);
  if (!props.item) {
    return <div className={styles['content-container']}></div>;
  }
  const onSave = () => {
    updateContext(userCtx, {
      id: props.item!.id,
      path: props.item!.path,
      name: props.item!.name,
      content: content,
    }).then(() => {
      props.onUpdated?.();
    });
  };
  return (
    <div className={styles['content-container']}>
      <Card
        width="100%"
        height="100%"
        head={
          <p>
            Content{' '}
            {
              <HelpIcon
                info={`Add the content for context ${props.item.name}.`}
              />
            }
            <span style={{ float: 'right', marginRight: '2rem' }}>
              <Button
                type="text"
                disabled={content === props.item.content}
                onClick={onSave}
              >
                <RiSaveFill style={{ verticalAlign: 'middle' }} />
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginLeft: '0.5rem',
                  }}
                >
                  SAVE
                </span>
              </Button>
            </span>
          </p>
        }
      >
        <textarea
          className={'textarea'}
          style={{ lineHeight: '2rem' }}
          placeholder="Add the content for the context here..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </Card>
    </div>
  );
};

export default Page;
