import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { AiOutlineQuestionCircle, AiOutlineUser } from 'react-icons/ai';
import { BsChatLeftText } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { GrTest } from 'react-icons/gr';
import { TbDatabaseCog } from 'react-icons/tb';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import AlertSystem from '../components/alert/AlertSystem';
import UserContext, { loadUserFromLocalStorage } from '../contexts/UserContext';
import logo from '../images/spark-icon.webp';
import { Nullable } from '../types/nullable';
import { User } from '../types/user';
import styles from './App.module.scss';

export type AppProps = {
  enabledComponents: Array<string>;
};

function App({ enabledComponents }: AppProps) {
  const cachedUser = loadUserFromLocalStorage();
  const [user, setUser] = useState<Nullable<User>>(cachedUser);

  const navigate = useNavigate();

  let sidbarItems: Array<SidebarItemProp> = [
    { label: 'Chat', path: 'chat', icon: <BsChatLeftText /> },
    { label: 'Context', path: 'context', icon: <TbDatabaseCog /> },
    { label: 'User', path: 'user', icon: <AiOutlineUser /> },
    { label: 'Setting', path: 'setting', icon: <FiSettings /> },
    { label: 'Playground', path: 'playground', icon: <GrTest /> },
    {
      label: 'About',
      path: 'about',
      icon: <AiOutlineQuestionCircle />,
    },
  ].filter((item) => enabledComponents.includes(item.path));

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AlertSystem />
      <div className={styles.layout}>
        <div className={styles.header}>
          <FiSettings
            className={styles.header_item}
            onClick={() => {
              navigate('setting');
            }}
          />
          <AiOutlineQuestionCircle
            className={styles.header_item}
            onClick={() => {
              navigate('about');
            }}
          />
        </div>
        <div className={styles.sidebar}>
          <div className={styles.logo_box}>
            <img src={logo} alt="logo" className={styles.logo} />
            <div className={styles.brand}>AI Spark 1.0</div>
          </div>
          {sidbarItems.map((item) => (
            <SidebarItem key={item.label} {...item} />
          ))}
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default App;

type SidebarItemProp = {
  label: string;
  path: string;
  icon?: ReactNode;
};

const SidebarItem = ({ label, path, icon }: SidebarItemProp) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        classNames(styles.sidebar_item, {
          [styles.active]: isActive,
        })
      }
    >
      {icon}
      <span className={styles.label}>{label}</span>
    </NavLink>
  );
};
