import { useEffect, useState } from 'react';
import Button from '../../components/Button';
import Slider from '../../components/Slider';
import Space from '../../components/Space';
import styles from './page.module.scss';

function Page() {
  const [fontSize, setFontSize] = useState<number>(() => getRootFontSize());
  const [fontSizePreview, setFontSizePreview] = useState<number>(fontSize);

  useEffect(() => {
    setRootFontSize(fontSize);
  }, [fontSize]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>UI Setting</div>
      <div>
        <div className={styles.item}>
          <label className={styles.label}>Font Size:</label>
          <Slider
            value={fontSizePreview}
            min={10}
            max={32}
            step={1}
            onChange={setFontSizePreview}
            displayValueFunc={(v) => `${v}px`}
          />
          <Space />
          <Button
            disabled={fontSize === fontSizePreview}
            onClick={() => {
              setFontSize(fontSizePreview);
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Page;

function getRootFontSize(): number {
  const computedStyle = getComputedStyle(document.documentElement);
  return parseFloat(computedStyle.fontSize);
}

function setRootFontSize(fontSize: number) {
  document.documentElement.style.fontSize = `${fontSize}px`;
}
