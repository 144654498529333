let baseURL = '/api';

export let chatCompleteURL = baseURL + '/chat-completion/complete';

export let loginURL = baseURL + '/auth/login';
export let registerURL = baseURL + '/auth/register';

export let listContextURL = baseURL + '/context/list';
export let updateContextURL = baseURL + '/context/update';
export let createContextURL = baseURL + '/context/create';
