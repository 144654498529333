import { showAlert } from '../components/alert/AlertSystem';
import { UserContextType } from '../contexts/UserContext';
import { Nullable } from '../types/nullable';

export async function jsonPost(
  url: string,
  reqObj: object,
  userCtx?: UserContextType,
  abortSignal?: AbortSignal
): Promise<Nullable<object>> {
  try {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=UTF-8');
    if (userCtx?.user?.token) {
      headers.append('Authorization', 'Bearer ' + userCtx.user.token);
    }

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(reqObj),
      signal: abortSignal,
    });
    if (response.status < 200 || response.status >= 300) {
      switch (response.status) {
        case 401:
          showAlert('Please log in to continue.');
          break;
        case 403:
          showAlert('Admin access required.');
          break;
        case 404:
          break;
        default:
          showAlert(`Error ${response.status}: ${response.statusText}`);
          break;
      }
      return null;
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const resultObj = await response.json();
      return resultObj;
    }
    return null;
  } catch (e) {
    if (e instanceof Error && e.name !== 'AbortError') {
      showAlert(e.message);
    }
    return null;
  }
}

export async function jsonGet(
  url: string,
  userCtx?: UserContextType,
  abortSignal?: AbortSignal
): Promise<Nullable<object>> {
  try {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=UTF-8');
    if (userCtx?.user?.token) {
      headers.append('Authorization', 'Bearer ' + userCtx.user.token);
    }

    const response = await fetch(url, {
      method: 'GET',
      headers,
      signal: abortSignal,
    });
    if (response.status !== 200) {
      switch (response.status) {
        case 401:
          showAlert('Please log in to continue.');
          break;
        case 403:
          showAlert('Admin access required.');
          break;
        default:
          showAlert(`Error ${response.status}: ${response.statusText}`);
          break;
      }
      return null;
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const resultObj = await response.json();
      console.log(resultObj);
      return resultObj;
    }
    return null;
  } catch (e) {
    if (e instanceof Error && e.name !== 'AbortError') {
      showAlert(e.message);
    }
    return null;
  }
}
