import { ReactNode } from 'react';
import styles from './Card.module.scss';

type CardProps = {
  head?: ReactNode;
  children?: ReactNode;
  width?: string;
  height?: string;
};

function Card(props: CardProps) {
  const { head, children, width, height } = props;
  return (
    <div className={styles.card} style={{ width, height }}>
      <div className={styles.head}>{head}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default Card;
