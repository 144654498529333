import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import CreateRoutes from './routes';

CreateRoutes().then((router) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  // const isMobile = window.matchMedia('(max-width: 768px)').matches;

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
