import { useContext, useEffect, useState } from 'react';
import styles from './page.module.scss';

import classNames from 'classnames';

import SchoolContext from '../../contexts/SchoolContext';
import UserContext from '../../contexts/UserContext';
import { ContextItem } from '../../types/context';
import { Nullable } from '../../types/nullable';

import Chat from '../../components/Chat';
import { listContexts } from '../context/page';

const Page = () => {
  const userCtx = useContext(UserContext);
  const predefinedSchoolContext = useContext(SchoolContext);

  const [contexts, setContexts] = useState<Array<ContextItem>>([]);
  const [selContext, setSelContext] = useState<Nullable<ContextItem>>(null);

  useEffect(() => {
    if (!predefinedSchoolContext) {
      listContexts(userCtx).then((contexts) => {
        if (contexts === null) {
          return;
        }
        setContexts(contexts);
      });
    }
  }, [setContexts]);

  return (
    <div className={styles.container}>
      {!predefinedSchoolContext && (
        <div className={styles.name_container}>
          <ul>
            {contexts.map((ctx) => (
              <li
                key={ctx.name}
                className={classNames(styles.name_container_item, {
                  [styles.selected]: selContext && selContext.name === ctx.name,
                })}
                onClick={() => {
                  setSelContext(ctx);
                }}
              >
                {ctx.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {selContext && <Chat context={selContext} />}
      {predefinedSchoolContext && <Chat context={predefinedSchoolContext} />}
    </div>
  );
};

export default Page;
