import { FC, useContext, useState } from "react";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import UserContext, { saveUserToLocalStorage } from "../../contexts/UserContext";
import { AuthPageType } from "../../types/auth";
import styles from "./page.module.scss";

const Page: FC = () => {
  const userCtx = useContext(UserContext);
  const [authPageType, setAuthPageType] = useState<AuthPageType>(
    AuthPageType.Unknown
  );

  if (authPageType === AuthPageType.Unknown) {
    if (!userCtx.user) {
      setAuthPageType(AuthPageType.Login);
      return <div></div>;
    }
    setAuthPageType(AuthPageType.User);
    return <div></div>;
  }

  switch (authPageType) {
    case AuthPageType.Login:
      return <Login setAuthPageType={setAuthPageType} />;
    case AuthPageType.Register:
      return <Register setAuthPageType={setAuthPageType} />;
    case AuthPageType.User:
      return (
        <div className={styles.container}>
          <div className={styles["form-box"]}>
            <h2>User Profile</h2>
            <div className={styles.row}>
              <label>EMAIL:</label>
              <p>{userCtx.user?.email}</p>
            </div>
            <div className={styles.row}>
              <label>GROUP:</label>
              <p>{userCtx.user?.userGroup}</p>
            </div>
            <button
              className={styles.button}
              onClick={() => {
                userCtx.setUser(null);
                saveUserToLocalStorage(null);
                setAuthPageType(AuthPageType.Login);
              }}
            >
              Sign out
            </button>
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};

export default Page;
