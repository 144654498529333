import { FC } from 'react';

export type SpaceProps = {
  unit?: number;
};

const Space: FC<SpaceProps> = (props) => {
  const unit = props.unit ?? 1;
  return <div style={{ display: 'inline-block', width: `${unit}rem` }} />;
};

export default Space;
