import { useState } from 'react';
import { IoSend } from 'react-icons/io5';
import Button from '../../components/Button';
import Card from '../../components/Card';
import HelpIcon from '../../components/HelpIcon';
import { AlertKind } from '../../components/alert/Alert';
import { showAlert } from '../../components/alert/AlertSystem';
import { chatCompleteURL } from '../../configs/api';
import { MsgRole } from '../../types/msg';
import { Nullable } from '../../types/nullable';
import { jsonPost } from '../../utils/http';
import styles from './page.module.scss';

type TokenStat = {
  promptTokens: number;
  completionTokens: number;
  totalTokens: number;
};
type Response = {
  tokenUsage: TokenStat;
  message: string;
};
// Request for answer using the specified context.
async function requestForAnswer(
  context: string,
  question: string,
  abortSignal?: AbortSignal
): Promise<Nullable<Response>> {
  const response: any = await jsonPost(
    chatCompleteURL,
    {
      messages: [
        {
          text: context,
          role: MsgRole.System,
        },
        {
          text: question,
          role: MsgRole.User,
        },
      ],
    },
    undefined,
    abortSignal
  );
  if (response === null) {
    return null;
  }
  return {
    tokenUsage: response.tokenUsage,
    message: response.message.text,
  };
}

export default function Page() {
  const [context, setContext] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');
  const [tokenStat, setTokenStat] = useState<TokenStat>({
    promptTokens: 0,
    completionTokens: 0,
    totalTokens: 0,
  });

  const onAsk = () => {
    if (context === '') {
      showAlert('Please input the context first.', AlertKind.WARNING);
      return;
    }
    if (question === '') {
      showAlert('Please input the question first.', AlertKind.WARNING);
      return;
    }

    requestForAnswer(context, question).then((response) => {
      if (response === null) {
        showAlert('Failed to get the answer.');
        return;
      }
      setAnswer(response.message);
      setTokenStat(response.tokenUsage);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles['context-container']}>
        <Card
          width='100%'
          height='100%'
          head={
            <p>
              Context{' '}
              {<HelpIcon info='Input your context here as the prompt.' />}
            </p>
          }
        >
          <textarea
            value={context}
            onChange={(e) => setContext(e.target.value)}
            placeholder='Add the content for the context here...'
            className={styles.textarea}
          />
        </Card>
      </div>
      <div className={styles['chat-container']}>
        <div className={styles['question-container']}>
          <Card
            width='100%'
            height='100%'
            head={
              <p>
                Question {<HelpIcon info='Input your question here.' />}
                <span style={{ float: 'right', marginRight: '2rem' }}>
                  <Button type='text' onClick={onAsk}>
                    <IoSend style={{ verticalAlign: 'middle' }} />
                    <span
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginLeft: '0.5rem',
                      }}
                    >
                      ASK
                    </span>
                  </Button>
                </span>
              </p>
            }
          >
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder='Add question here...'
              className={styles.textarea}
            />
          </Card>
        </div>
        <div className={styles['answer-container']}>
          <Card
            width='100%'
            height='100%'
            head={
              <p>
                Answer {<HelpIcon info='The answer will be displayed here.' />}
              </p>
            }
          >
            <textarea value={answer} className={styles.textarea} readOnly />
          </Card>
        </div>
        <div className={styles['stat-container']}>
          <Card
            width='100%'
            height='100%'
            head={
              <p>
                Statistics{' '}
                {
                  <HelpIcon info='Some useful statistics will be displayed here.' />
                }
              </p>
            }
          >
            <div className={styles.stat}>
              <p>
                <span>Prompt Tokens:</span>
                <span> </span>
                <span>{tokenStat.promptTokens}</span>
              </p>
              <p>
                <span>Completion Tokens:</span>
                <span> </span>
                <span>{tokenStat.completionTokens}</span>
              </p>
              <p>
                <span>Total Tokens:</span>
                <span> </span>
                <span>{tokenStat.totalTokens}</span>
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
