import React, { FC } from 'react';
import styles from './Button.module.scss';

export type ButtonProps = {
  type?: 'contained' | 'outlined' | 'text';
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = (props) => {
  const { children, onClick, disabled } = props;
  let { type } = props;

  if (!type) {
    type = 'contained';
  }

  return (
    <button
      className={styles[`button-${type}`]}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
