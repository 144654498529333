export enum MsgRole {
  System = 'SYSTEM',
  User = 'USER',
  Assistant = 'AI',
}

export type Msg = {
  text: string;
  time: string;
  role: MsgRole;
};
