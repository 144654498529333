import { produce } from 'immer';
import { encodingForModel } from 'js-tiktoken';
import { useEffect, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { chatCompleteURL } from '../configs/api';
import { ContextItem } from '../types/context';
import { Msg, MsgRole } from '../types/msg';
import { Nullable } from '../types/nullable';
import { jsonPost } from '../utils/http';
import { formatCurrentTime } from '../utils/time';
import { showAlert } from './alert/AlertSystem';
import Button from './Button';
import Card from './Card';
import styles from './Chat.module.scss';
import ChatInput from './ChatInput';
import HelpIcon from './HelpIcon';
import Message from './Message';

const maxContextLength = 110000;

// Request for answer using the specified context.
async function requestForAnswer(
  messages: Array<Msg>,
  abortSignal?: AbortSignal
): Promise<Nullable<Msg>> {
  const response: any = await jsonPost(
    chatCompleteURL,
    {
      messages,
    },
    undefined,
    abortSignal
  );
  if (response === null) {
    return null;
  }
  console.log(response.tokenUsage);
  const msg = response.message;
  return {
    text: msg.text,
    role: msg.role,
    time: formatCurrentTime(),
  };
}

var abortController: AbortController | undefined;

// Truncate messages to fit the maximum length.
// System messages are not truncated.
// Last two messages are always kept.
function truncateMessages(messages: Array<Msg>, maxLength: number): Array<Msg> {
  const enc = encodingForModel('gpt-4o');
  const tokenCounts = messages.map((msg) => enc.encode(msg.text).length);
  let tokenCountsSum = tokenCounts.reduce((a, b) => a + b, 0);
  if (tokenCountsSum <= maxLength) {
    return messages;
  }

  const newMessages: Array<Msg> = [];
  newMessages.push(messages[0]);
  let i = 1;
  for (; i < messages.length - 2; i++) {
    tokenCountsSum -= tokenCounts[i];
    if (tokenCountsSum <= maxLength) {
      break;
    }
  }
  if (tokenCountsSum > maxLength) {
    showAlert('The context is too long.');
  }
  for (; i < messages.length; i++) {
    newMessages.push(messages[i]);
  }
  return newMessages;
}

export type ChatProps = {
  context: ContextItem;
};

const Chat = (props: ChatProps) => {
  const [messages, setMessages] = useState<Msg[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);

  let systemDate = new Date();
  systemDate.setFullYear(2020);
  const preMessages: Msg[] = [
    {
      text: props.context.content,
      role: MsgRole.System,
      time: formatCurrentTime(systemDate),
    },
    {
      text: `Hello, how can I help you today about ${props.context.name}?`,
      role: MsgRole.Assistant,
      time: formatCurrentTime(),
    },
  ];

  useEffect(() => {
    if (bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  useEffect(() => {
    if (messages.length === 0) {
      return;
    }
    if (messages[messages.length - 1].role !== MsgRole.User) {
      return;
    }
    setIsLoading(true);

    abortController = new AbortController();
    requestForAnswer([...preMessages, ...messages], abortController.signal)
      .then(pushMessage)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [messages.length]);

  const pushMessage = (msg: Msg | null) => {
    if (msg === null) {
      return;
    }
    const newMessages = produce(messages, (messages) => {
      messages.push(msg);
    });
    setMessages(truncateMessages(newMessages, maxContextLength));
  };

  const handleSendMessage = (inputContent: string) => {
    if (inputContent) {
      pushMessage({
        text: inputContent,
        role: MsgRole.User,
        time: formatCurrentTime(),
      });
    }
  };

  const onRefresh = () => {
    abortController?.abort();
    setMessages([]);
  };

  return (
    <div className={styles.container}>
      <Card
        head={
          <p>
            Chat{' '}
            {<HelpIcon info="Ask the chatbot questions about the product." />}
            <span style={{ float: 'right', marginRight: '2rem' }}>
              <Button type="text" onClick={onRefresh}>
                <FaTrash style={{ verticalAlign: 'middle' }} />
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginLeft: '0.5rem',
                  }}
                >
                  CLEAR CONVERSATION
                </span>
              </Button>
            </span>
          </p>
        }
        height="100%"
        width="100%"
      >
        <div className={styles.wrapper}>
          <div className={styles.messages}>
            {[...preMessages, ...messages]
              .filter((msg) => msg.role !== MsgRole.System)
              .map((message, index) => {
                return <Message key={index} message={message} />;
              })}
            {isLoading && <div className={styles.loading}></div>}
            <div ref={bottomRef}></div>
          </div>
          <ChatInput
            placeholder="Type your question here..."
            onSubmit={handleSendMessage}
          />
        </div>
      </Card>
    </div>
  );
};

export default Chat;
