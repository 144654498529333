import { FC } from 'react';
import jasPic from './Jas.jpeg';
import williamPic from './William.jpeg';
import derekPic from './derek.png';
import nancyPic from './nancy.jpg';
import gracePic from './grace.jpeg';
import ningyuanPic from './ningyuan.jpg';


import styles from './page.module.scss';

const Page: FC = () => {
  return (
    <div>


      <div className={styles['about-section']}>
        <h1>About Us</h1>
        <p>AI Chatbot Team</p>
        <p>The Highschoolers who created this product</p>
      </div>
      <h2 className={styles.team} style={{ textAlign: 'center' }}>
        Our Team
      </h2>

      <div className={styles.cards}>

        <div className={styles.card}>
          <div className={styles.container}>
            <img src={williamPic} alt='william' />
            <div className={styles.words}>
              <div className={styles.name}>William Wang</div>
              <div className={styles.email}></div>
              <div className={styles.position}>CTO & Co-Founder</div>
              <div className={styles.description}>
                My name is William Wang. I am a senior at MSJHS (currently) and
                the CTO of our company (AI venture). In my spare time, I like to
                read, write creatively, and study machine learning
              </div>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.container}>
            <img src={jasPic} alt='jas' />
            <div className={styles.words}>
              <div className={styles.name}>Jasamarbir Singh Arora</div>
              <div className={styles.email}></div>
              <div className={styles.position}>Developer & Co-Founder</div>
              <div className={styles.description}>
                Hello, I am Jasamarbir. Currently I am a sophomore from American
                High School. Computer science and Artificial intelligence are
                fields I enjoy learning about, I hope this application will be
                beneficial for you.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.container}>
            <img src={nancyPic} alt='nancy' />
            <div className={styles.words}>
              <div className={styles.name}>Nancy</div>
              <div className={styles.email}>nancy102711@gmail.com</div>
              <div className={styles.position}>Developer & Co-Founder</div>
              <div className={styles.description}>
                Hi, I'm Nancy. Now I'm a junior from Redwood Christian school. I
                really enjoy studying computer science especially AI. I hope our
                program can provide convenience for you.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.container}>
            <img src={derekPic} alt='derek' />
            <div className={styles.words}>
              <div className={styles.name}>Derek</div>
              <div className={styles.email}></div>
              <div className={styles.position}>Developer & Co-Founder</div>
              <div className={styles.description}>
                Currently, I am a junior at Irvington High School. I'm
                interested in computer science and machine learning.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.container}>
            <img src={gracePic} alt='grace' />
            <div className={styles.words}>
              <div className={styles.name}>Yunjiao (Grace) Xiao</div>
              <div className={styles.email}>gracexiao26@gmail.com</div>
              <div className={styles.position}>Developer & Co-Founder</div>
              <div className={styles.description}>
              Hello, I am Grace. I'm a senior at Mission San Jose High School, 
              and I enjoy researching AI & technology. In my spare time, 
              I manage my art account (https://sites.google.com/view/cloud-dumplings) 
              and go on hikes.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.container}>
            <img src={ningyuanPic} alt='ningyuan' />
            <div className={styles.words}>
              <div className={styles.name}>Wang Ningyuan</div>
              <div className={styles.email}>wangningyuan5@gmail.com</div>
              <div className={styles.position}>Propagandize/Ideation Work</div>
              <div className={styles.description}>
              My name is Ningyuan Wang, and I'm a junior at Redwood Christian. 
              With previous experience in computer studies and basic coding skills, 
              I have a keen interest in AI and computers. I am capable of handling 
              publicity work.
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  );
};

export default Page;
