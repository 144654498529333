import { RouteObject, createBrowserRouter } from 'react-router-dom';
import App from './app/App';
import AboutPage from './app/about/page';
import ChatPage from './app/chat/page';
import ContextPage, { listContexts } from './app/context/page';
import PlaygroundPage from './app/playground/page';
import SettingPage from './app/setting/page';
import UserPage from './app/user/page';
import SchoolContext from './contexts/SchoolContext';

const DefaultRouteChildren = [
  { path: 'chat', element: <ChatPage /> },
  { path: 'setting', element: <SettingPage /> },
];

const DefaultRoute: RouteObject = {
  path: '/',
  element: <App enabledComponents={DefaultRouteChildren.map((r) => r.path)} />,
  children: DefaultRouteChildren,
};

const AdminRouteChildren = [
  { path: 'chat', element: <ChatPage /> },
  { path: 'context', element: <ContextPage /> },
  { path: 'user', element: <UserPage /> },
  { path: 'setting', element: <SettingPage /> },
  { path: 'playground', element: <PlaygroundPage /> },
  { path: 'about', element: <AboutPage /> },
];

const AdminRoute: RouteObject = {
  path: '/admin',
  element: <App enabledComponents={AdminRouteChildren.map((r) => r.path)} />,
  children: AdminRouteChildren,
};

export default async function CreateRoutes() {
  const contexts = await listContexts(undefined);
  const SchoolSpecialRoutes = contexts?.map((ctx) => ({
    path: ctx.path,
    element: (
      <SchoolContext.Provider value={ctx}>
        <App enabledComponents={DefaultRouteChildren.map((r) => r.path)} />
      </SchoolContext.Provider>
    ),
    children: DefaultRouteChildren,
  }));
  return createBrowserRouter([
    DefaultRoute,
    AdminRoute,
    ...(SchoolSpecialRoutes || []),
  ]);
}
