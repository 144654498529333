export async function hashPassword(password: string): Promise<string> {
  if (!window.crypto || !window.crypto.subtle) {
    throw new Error('Browser does not support Web Crypto API');
  }
  const encoder = new TextEncoder();
  const data = encoder.encode(password + 'aisparkhs-chatbot');

  const buffer = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(buffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}
