import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { IoSend } from 'react-icons/io5';
import styles from './ChatInput.module.scss';

export type ChatInputProps = {
  placeholder?: string;
  onSubmit?: (content: string) => void;
};

const ChatInput: FC<ChatInputProps> = (props) => {
  const [content, setContent] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { placeholder, onSubmit } = props;

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight + 1}px`;
    }
  }, [textareaRef, content]);

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setContent(e.target.value);
  };

  const onBtnClick = () => {
    onSubmit?.(content);
    setContent('');
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onBtnClick();
    }
  };

  return (
    <div className={styles.container}>
      <textarea
        ref={textareaRef}
        value={content}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rows={1}
        className={styles.textarea}
      />
      <button
        onClick={onBtnClick}
        className={classNames(styles.button, {
          [styles.activated]: content !== '',
        })}
      >
        <IoSend />
      </button>
    </div>
  );
};

export default ChatInput;
