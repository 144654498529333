import { AiFillInfoCircle } from 'react-icons/ai';
import styles from './Alert.module.scss';

export enum AlertKind {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type AlertProps = {
  kind: AlertKind;
  message: string;
};

export const Alert = (props: AlertProps) => {
  const { kind, message } = props;

  return (
    <div className={styles[`alert-${kind}`]}>
      <p>
        <AiFillInfoCircle className={styles.icon} />
        <span className={styles.message}>{message}</span>
      </p>
    </div>
  );
};

export default Alert;
