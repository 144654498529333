import { FC, useContext, useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";
import { loginURL } from "../../configs/api";
import UserContext, {
  saveUserToLocalStorage,
} from "../../contexts/UserContext";
import { AuthPageType } from "../../types/auth";
import { User } from "../../types/user";
import { hashPassword } from "../../utils/hash";
import { jsonPost } from "../../utils/http";
import { AlertKind } from "../alert/Alert";
import { showAlert } from "../alert/AlertSystem";
import styles from "./AuthComponent.module.scss";

export type LoginProps = {
  setAuthPageType?: (authPageType: AuthPageType) => void;
};

async function loginWithEmailAndPassword(
  email: string,
  password: string
): Promise<User> {
  const result: any = await jsonPost(loginURL, {
    email: email,
    password: await hashPassword(password),
  });

  if (!result || !result.email || !result.token) {
    throw new Error("Login failed");
  }

  return result;
}

const Login: FC<LoginProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginBtnEnabled, setLoginBtnEnabled] = useState<boolean>(true);
  const userCtx = useContext(UserContext);
  const { setAuthPageType } = props;

  const login = async () => {
    if (email === "" || password === "") {
      showAlert("Email and password are required", AlertKind.WARNING);
      return;
    }
    setLoginBtnEnabled(false);
    try {
      let user: User;
      user = await loginWithEmailAndPassword(email, password);
      saveUserToLocalStorage(user);
      userCtx.setUser(user);
    } catch (e) {
      console.error(e);
      showAlert("Login failed");
      return;
    } finally {
      setLoginBtnEnabled(true);
    }

    setAuthPageType?.(AuthPageType.User);
  };

  return (
    <section className={styles.section}>
      <div className={styles['form-box']}>
        <div className={styles["form-value"]}>
          <h2>Login</h2>
          <div className={styles.inputbox}>
            <MdOutlineMail className={styles.icon} />
            <input
              type="email"
              placeholder=" "
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label>Email</label>
          </div>
          <div className={styles.inputbox}>
            <BiLockAlt className={styles.icon} />
            <input
              type="password"
              placeholder=" "
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <label>Password</label>
          </div>
          {/* <div className={styles.forget}>
            <span
              className={styles.a}
              onClick={() => {
                setAuthPageType?.(AuthPageType.ResetPassword);
              }}
            >
              Forget Password
            </span>
          </div> */}
          <button
            className={styles.button}
            onClick={login}
            disabled={!loginBtnEnabled}
          >
            Log in
          </button>
          <div className={styles.register}>
            <p>
              Don't have an account?{" "}
              <span
                className={styles.a}
                onClick={() => {
                  setAuthPageType?.(AuthPageType.Register);
                }}
              >
                Register
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
