import { FC, useContext, useState } from 'react';
import { BiLockAlt } from 'react-icons/bi';
import { MdOutlineMail } from 'react-icons/md';
import { RouteObject } from 'react-router-dom';
import { registerURL } from '../../configs/api';
import UserContext from '../../contexts/UserContext';
import { AuthPageType } from '../../types/auth';
import { User } from '../../types/user';
import { validateEmailAndPassword } from '../../utils/check';
import { hashPassword } from '../../utils/hash';
import { jsonPost } from '../../utils/http';
import { AlertKind } from '../alert/Alert';
import { showAlert } from '../alert/AlertSystem';
import styles from './AuthComponent.module.scss';

export type RegisterProps = {
  setAuthPageType?: (authPageType: AuthPageType) => void;
};

async function registerUserWithEmailAndPassword(
  email: string,
  password: string
): Promise<User> {
  const result: any = await jsonPost(registerURL, {
    email: email,
    password: await hashPassword(password),
  });

  if (!result || !result.email || !result.token) {
    throw new Error('Registration failed');
  }

  return result;
}

export const Register: FC<RegisterProps> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [registerBtnEnabled, setRegisterBtnEnabled] = useState<boolean>(true);
  const userCtx = useContext(UserContext);
  const { setAuthPageType } = props;

  const register = async () => {
    if (password !== password2) {
      showAlert('Passwords do not match', AlertKind.WARNING);
      return;
    }

    const valid = validateEmailAndPassword(email, password);
    if (valid !== true) {
      showAlert(valid, AlertKind.WARNING);
      return;
    }

    setRegisterBtnEnabled(false);
    try {
      let user: User;
      user = await registerUserWithEmailAndPassword(email, password);
      userCtx.setUser(user);
    } catch (e: any) {
      console.error(e);
      showAlert('Registration failed. Please try again later.');
      return;
    } finally {
      setRegisterBtnEnabled(true);
    }

    setAuthPageType?.(AuthPageType.User);
  };

  return (
    <section className={styles['section']}>
      <div className={styles['form-box']}>
        <div className={styles['form-value']}>
          <h2>Register</h2>
          <div className={styles.inputbox}>
            <MdOutlineMail className={styles.icon} />
            <input
              type='email'
              placeholder=' '
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label>Email</label>
          </div>
          <div className={styles.inputbox}>
            <BiLockAlt className={styles.icon} />
            <input
              type='password'
              placeholder=' '
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <label>Password</label>
          </div>
          <div className={styles.inputbox}>
            <BiLockAlt className={styles.icon} />
            <input
              type='password'
              placeholder=' '
              value={password2}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
            />
            <label>Confirm Password</label>
          </div>
          <button
            className={styles.button}
            onClick={register}
            disabled={!registerBtnEnabled}
          >
            Register
          </button>
          <div className={styles.register}>
            <p>
              Already have an account?{' '}
              <span
                className={styles.a}
                onClick={() => {
                  setAuthPageType?.(AuthPageType.Login);
                }}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
