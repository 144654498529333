import React, { useContext } from 'react';
import UserContext from '../contexts/UserContext';
import bot from '../images/bot.svg';
import defaultUser from '../images/user.webp';
import { Msg, MsgRole } from '../types/msg';
import MarkdownComponent from './MarkdownComponent';
import styles from './Message.module.scss';

export type MessageProps = {
  message: Msg;
};

const Message: React.FC<MessageProps> = (props) => {
  const { message } = props;
  const userCtx = useContext(UserContext);

  let picture = <img className={styles.image} src={bot} alt="Bot" />;
  let name = 'ChatBot';

  if (message.role === MsgRole.User) {
    name = userCtx.user?.email ?? 'User';
    picture = <img className={styles.image} src={defaultUser} alt="User" />;
  }
  return (
    <div className={styles['message-with-profile']}>
      <div className={styles.profile}>
        {picture}
        <span className={styles.name}>{name}</span>
        <span className={styles.time}>{message.time}</span>
      </div>
      <div className={styles.message}>
        <MarkdownComponent content={message.text} />
      </div>
    </div>
  );
};

export default Message;
