export function validateEmailAndPassword(email: string, password: string) {
  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regular expressions to check password policy
  const nonAlphaRegex = /[^a-zA-Z]/;

  // Check if email is empty
  if (!email || email.trim() === '') {
    return 'Email is required';
  }

  // Check if password is empty
  if (!password || password.trim() === '') {
    return 'Password is required';
  }

  // Check email format
  if (!emailRegex.test(email)) {
    return 'Invalid email format';
  }

  // Check password length
  if (password.length < 6 || password.length > 4096) {
    return 'Password length must be between 6 and 4096 characters';
  }

  // Check password policy
  if (!nonAlphaRegex.test(password)) {
    return 'Password must contain at least one non-alphabetical character';
  }

  // If all checks pass, return true to indicate valid email and password
  return true;
}
