import React from "react";
import { Nullable } from "../types/nullable";
import { User } from "../types/user";

export type UserContextType = {
  user: Nullable<User>;
  setUser: (user: Nullable<User>) => void;
};

const UserContext = React.createContext<UserContextType>(null!);

export default UserContext;

const USER_LOCAL_STORAGE_KEY = "user";
const USER_EXPIRE_MILLIS = 1000 * 60 * 60 * 24; // Expire after 24 hours

export function loadUserFromLocalStorage(): Nullable<User> {
  let item = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  if (item == null) {
    return null;
  }
  const obj = JSON.parse(item);
  if (!obj.expire || obj.expire < Date.now()) {
    return null;
  }
  return obj;
}

export function saveUserToLocalStorage(user: Nullable<User>) {
  if (!user) {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    return;
  }
  (user as any).expire = Date.now() + USER_EXPIRE_MILLIS;
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}
