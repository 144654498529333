import { FC } from 'react';

export type SliderProps = {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange?: (value: number) => void;
  displayValueFunc?: (value: number) => string;
};

const Slider: FC<SliderProps> = (props) => {
  let displayValue = props.value.toString();
  if (props.displayValueFunc) {
    displayValue = props.displayValueFunc(props.value);
  }
  return (
    <label>
      <span style={{ marginRight: '1rem' }}>
        <input
          type='range'
          value={props.value}
          min={props.min}
          max={props.max}
          step={props.step}
          onChange={(e) => {
            props.onChange?.(parseInt(e.target.value));
          }}
        />
      </span>
      <span>{displayValue}</span>
    </label>
  );
};

export default Slider;
