import React from 'react';
import { marked } from 'marked';
import styles from './MarkdownComponent.module.scss'

const MarkdownComponent = ({ content }:{content: string}) => {
    const htmlContent = marked(content);

    return (
        <div className={styles.container} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default MarkdownComponent;
