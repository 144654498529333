import { FC } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import styles from './HelpIcon.module.scss';

type HelpIconProps = {
  info: string;
};

const HelpIcon: FC<HelpIconProps> = (props) => {
  return (
    <span className={styles['help-icon']}>
      <span className={styles.icon}>
        <BiHelpCircle />
      </span>
      <span className={styles.info}>{props.info}</span>
    </span>
  );
};

export default HelpIcon;
