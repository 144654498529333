import { produce } from 'immer';
import { useEffect, useState } from 'react';
import Alert, { AlertKind } from './Alert';
import styles from './AlertSystem.module.scss';

var addAlert: ((kind: AlertKind, message: string) => void) | null = null;

export const showAlert = (
  message: string,
  kind: AlertKind = AlertKind.ERROR
) => {
  if (addAlert === null) {
    console.log(`Alert: ${kind} ${message}`);
  } else {
    addAlert(kind, message);
  }
};

type AlertItem = {
  kind: AlertKind;
  message: string;
  time: Date;
};

const AlertSystem = () => {
  const [alerts, setAlerts] = useState<Array<AlertItem>>([]);

  useEffect(() => {
    const timer = setInterval(() => {
      const newAlerts = produce(alerts, (draft: Array<AlertItem>) => {
        const now = new Date();
        while (
          draft.length > 0 &&
          now.getTime() - draft[draft.length - 1].time.getTime() > 5000
        ) {
          draft.pop();
        }
      });
      setAlerts(newAlerts);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  addAlert = (kind: AlertKind, message: string) => {
    setAlerts(
      produce((draft) => {
        draft.unshift({ kind, message, time: new Date() });
      })
    );
  };

  const display = alerts.length > 0 ? 'block' : 'none';
  return (
    <div className={styles.container} style={{ display }}>
      <div className={styles.box}>
        {alerts.map((alert, index) => (
          <Alert key={index} kind={alert.kind} message={alert.message} />
        ))}
      </div>
    </div>
  );
};

export default AlertSystem;
