// formatCurrentTime() returns a string of the current time formatted.
export function formatCurrentTime(now: Date = new Date()): string {
  // Get month, day, year, hours, minutes, and AM/PM
  let month = now.getMonth() + 1; // Months are zero-indexed
  let day = now.getDate();
  let year = now.getFullYear();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  // Add leading zeros if necessary
  let monthstr = month < 10 ? '0' + month : month;
  let daystr = day < 10 ? '0' + day : day;
  let hourstr = hours < 10 ? '0' + hours : hours;
  let minutestr = minutes < 10 ? '0' + minutes : minutes;

  // Create the formatted time string
  const formattedTime = `${monthstr}/${daystr}/${year} ${hourstr}:${minutestr} ${ampm}`;
  return formattedTime;
}
